.wildMagic {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wildMagic h2 {
    margin-bottom: 0;
}

.wildMagicTopSpacer {
    flex: 1;
}

.wildMagicContent,
.wildMagicBottomSpacer {
    flex: 2;
}

.wildMagic .input {
    width: 40px;
    font-size: 18px;
    text-align: center;
}

.wildMagicRollAndResult {
    padding: 10px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wildMagicButton {
    border: none;
    background-color: var(--c4);
    padding: 8px 16px;
    margin: 0 4px;
    width: 70px;
    border-radius: 4px;
    font-size: 14px;
    user-select: none;
}
