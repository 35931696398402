.picker {
    border-radius: 8px;
    padding: 4px;
    background-color: var(--c2);
    margin: 12px auto;
    width: min(100vw - 24px, 470px);
}

.picker .pickerTabs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.picker .tabHeader {
    flex: 1;
    padding: 12px 0;
    margin: 0;
    background-color: var(--c3);
    border-radius: 6px;
}

.picker .tabHeader:first-child {
    margin-right: 2px;
}

.picker .tabHeader:last-child {
    margin-left: 2px;
}

.picker .active.tabHeader {
    background-color: var(--c4);
}

.pickerContent {
    margin: 4px 0 0 0;
}

.pickerContent button,
.picker .option {
    border-radius: 8px;
    padding: .5rem .8rem;
    user-select: none;
}


.picker .options {
    /*margin: 0 auto;*/
    /*max-width: 440px;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.picker h5 {
    text-transform: capitalize;
}

.pickerContent button,
.picker .option {
    flex: 1 10 50px;
    margin: 4px;
    text-transform: capitalize;
}

.picker .option {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--c1);
}

.picker .active.option {
    background-color: var(--c4);
}

.picker .button.option {
    background-color: var(--c2);
}


