.spell {
    position: relative;
    background-color: var(--c3);
    margin: 4px;
    padding: 4px;
    border-radius: 8px;
    text-align: left;

}

.spellHeader {
    height: 30px;
    display: flex;
}

.spellHeaderText {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--c1);
    padding: 4px 0;
    border-radius: 6px;
}

.spellName {
    font-size: 20px;
    float: left;
    margin-left: 8px;
}

.spellLvl {
    margin-top: 4px;
    float: right;
    margin-right: 8px;
}

.spellDetails {
    margin-top: 4px;
    background-color: var(--c2);
    padding: 1px 8px;
    border-radius: 6px;
}

.topDetails {
    display: flex;
    flex-wrap: wrap;
    padding: 0 -2px;
}

.topDetails div {
    padding: 0 4px;
}

.topDetails .detail {
    font-size: 14px;
    opacity: .7;
}

.topDetails > div {
    flex: 1;
}

.topDetails > div p {
    white-space: nowrap;
}

.topDetails > div p .wrappable {
    white-space: normal;
}

.spellDesc p:last-child {
    margin-bottom: 8px;
}


.spellDivider {
    height: 16px;
    font-size: 12px;
    width: calc(100vw - 60px);
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

}

.spellDivider > span {
    flex: 1;
    margin: 0 8px;
    color: var(--c3);
}

.spellDivider > div {
    flex: 1000 1;
    height: 1px;
    background-color: var(--c3);
    width: 1px;
}

.spellResultWrapper {
    position: relative;
}

.adding.spell .spellHeader{
    opacity: 0;
}


.spell.shadow {
    z-index: 1;
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
}

.spell.shadow.fadeIn {
    z-index: 1;
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.spell.shadow.fadeIn.gone {
    opacity: 1;
}

.spell.shadow.go {
    z-index: 2;
    transition: all 300ms ease-in;
}

.spell.shadow.go.gone {
    left: 100vw;
    right: -100vw;
}
