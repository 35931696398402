.spells {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.spellsContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-y: auto;

    padding-bottom: 2px;
    padding-top: 60px;
}

.spellsContent > svg {
    max-width: 80vw;
    max-height: 80vh;
}

.spellsHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(#312939, #312939, #31293900);
    pointer-events: none;
    z-index: 1;
}
